import { HeroSection } from '@/slices/HeroSection'
import { Services } from '@/slices/Services'
import { SliceZoneFragment } from '@/types/graphql'
import { graphql } from 'gatsby'
import React from 'react'

export const fragment = graphql`
  fragment SliceZoneFragment on PrismicSiteDataType {
    body {
      __typename

      ... on PrismicSiteBodyHeroSection {
        id
        ...HeroSectionFragment
      }

      ... on PrismicSiteBodyServices {
        id
        ...ServicesFragment
      }
    }
  }
`

type Props = {
  data: SliceZoneFragment
}

export const SliceZone: React.FC<Props> = ({ data }) => {
  return (
    <>
      {data.body.map(slice => {
        switch (slice.__typename) {
          case 'PrismicSiteBodyHeroSection':
            return <HeroSection key={slice.id} data={slice} />
          case 'PrismicSiteBodyServices':
            return <Services key={slice.id} data={slice} />
          default:
            const _exhaustiveCheck: never = slice
            return _exhaustiveCheck
        }
      })}
    </>
  )
}
