import { DefaultLocaleKind, localeConfig } from '@/config/i18n'
import { useLocale } from '@/store/locale'
import { useTheme } from '@/store/theme'
import { useOnClickOutside } from '@apostrof/lib'
import { css } from '@emotion/core'
import { Link } from 'gatsby'
import React, { useRef, useState } from 'react'

const elementIds = {
  button: 'locale-switcher-button',
  dropdown: 'locale-switcher-dropdown',
}

export const LocaleSwitcher: React.FC = () => {
  const theme = useTheme()
  const currentLocale = useLocale()
  const [isExpanded, setExpanded] = useState(false)

  const currentLocaleLabel = localeConfig[currentLocale].switcherLabel.compact

  const buttonStyle = {
    normal: {
      bg: theme.colors.grays[8],
      text: theme.colors.grays[4],
    },
    hover: {
      bg: theme.colors.grays[7],
      text: theme.colors.grays[3],
    },
  }

  const dropdownBgColor = theme.colors.grays[8]

  const dropdownLinkStyle = {
    normal: {
      text: theme.colors.grays[4],
    },
    hover: {
      bg: theme.colors.grays[7],
      text: theme.colors.grays[3],
    },
  }

  // Clicking outside the button or the dropdown will close it
  const ref = useRef<HTMLDivElement>(null)
  useOnClickOutside(ref, () => setExpanded(false))

  function handleClick() {
    setExpanded(prevState => !prevState)
  }

  return (
    <div
      ref={ref}
      css={css`
        position: relative;

        white-space: nowrap;
      `}
    >
      <button
        id={elementIds.button}
        type="button"
        onClick={handleClick}
        aria-controls={elementIds.dropdown}
        aria-expanded={isExpanded}
        css={css`
          cursor: pointer;
          padding: 0.5em 1em;
          border: none;
          background: ${buttonStyle.normal.bg};
          border-radius: ${theme.radii.pill};

          transition: all 0.1s ease-out;

          color: ${buttonStyle.normal.text};

          &:hover,
          &[aria-expanded='true'] {
            background: ${buttonStyle.hover.bg};
            color: ${buttonStyle.hover.text};
          }
        `}
      >
        <span role="img" aria-label="Globe">
          🌐
        </span>
        <span
          css={css`
            margin-left: 0.5em;

            text-transform: uppercase;
            letter-spacing: 0.03em;
          `}
        >
          {currentLocaleLabel}
        </span>
      </button>

      <ul
        id={elementIds.dropdown}
        aria-expanded={isExpanded}
        aria-hidden={!isExpanded}
        role="region"
        css={css`
          position: absolute;
          top: calc(100% + 1em);
          right: 0;
          z-index: 1;
          background: ${dropdownBgColor};
          padding: 0.5em;
          border-radius: 1.7em;
          list-style: none;
          box-shadow: ${theme.elevations[1]};

          transition: all 0.1s ease-out;
          transform-origin: top right;
          will-change: transform, opacity;

          &[aria-expanded='false'] {
            opacity: 0;
            transform: rotate3d(1, 1, 0, 15deg);
            pointer-events: none;
          }

          /* triangle */
          &::before {
            content: '';
            display: block;
            position: absolute;
            z-index: -1;
            top: 1px;
            right: 1.5em;
            width: 12px;
            height: 12px;
            background-color: inherit;
            border-radius: 2px 0 12px 0;

            transform: translateY(-50%) rotate(45deg);
          }
        `}
      >
        {Object.entries(localeConfig)
          .filter(([localeCode]) => localeCode !== currentLocale)
          .map(([localeCode, config]) => (
            <li
              key={localeCode}
              css={css`
                &:not(:first-of-type) {
                  margin-top: 0.25em;
                }

                &:first-of-type {
                  a {
                    border-top-left-radius: 1.25em;
                    border-top-right-radius: 1.25em;
                  }
                }

                &:last-of-type {
                  a {
                    border-bottom-left-radius: 1.25em;
                    border-bottom-right-radius: 1.25em;
                  }
                }
              `}
            >
              <Link
                to={
                  config.kind === DefaultLocaleKind.TRUE
                    ? '/'
                    : `/${config.pathPrefix}/`
                }
                css={css`
                  display: block;
                  padding: 0.75em 1.5em;
                  border-radius: ${theme.radii.normal};

                  color: ${dropdownLinkStyle.normal.text};
                  text-align: right;
                  text-decoration: none;
                  white-space: nowrap;

                  transition: all 0.1s ease-out;

                  &:hover {
                    background: ${dropdownLinkStyle.hover.bg};
                    color: ${dropdownLinkStyle.hover.text};
                  }
                `}
              >
                {config.switcherLabel.extended}
              </Link>
            </li>
          ))}
      </ul>
    </div>
  )
}
