import { useTheme } from '@/store/theme'
import { useMetadata } from '@/utils/useMetadata'
import {
  ExternalLink,
  fluidFontSize,
  hexToRGB,
  PhoneLink,
  WhatsAppLink,
} from '@apostrof/lib'
import { css } from '@emotion/core'
import React from 'react'
import { LocaleSwitcher } from './LocaleSwitcher'

export const TopBar: React.FC = () => {
  const theme = useTheme()
  const { siteData, i18nData } = useMetadata()

  const buttonStyle = {
    normal: {
      bg: theme.colors.grays[8],
      text: theme.colors.grays[4],
    },
    hover: {
      bg: theme.colors.grays[7],
      text: theme.colors.grays[3],
    },
  }

  return (
    <section
      css={css`
        position: sticky;
        top: 0;
        z-index: 5;
        background: ${theme.colors.grays[9]};
        padding: 0 5%;

        display: flex;
        align-items: center;

        ${fluidFontSize(12, 14, 320, 1920)};
        white-space: nowrap;
      `}
    >
      <PhoneLink
        phone={siteData.phone_number}
        css={css`
          margin-right: 0.5em;

          color: ${theme.colors.grays[4]};
          text-decoration: none;
        `}
      >
        {siteData.phone_number}
      </PhoneLink>

      <div
        css={css`
          overflow: hidden;

          position: relative;
          &::before,
          &::after {
            position: absolute;
            top: 0;
            content: '';
            z-index: 1;
            height: 100%;
            width: 1.5em;
          }

          &::before {
            left: 0;
            background-image: linear-gradient(
              to right,
              ${hexToRGB(theme.colors.grays[9], 0.8)},
              transparent
            );
          }

          &::after {
            right: 0;
            background-image: linear-gradient(
              to left,
              ${hexToRGB(theme.colors.grays[9], 0.8)},
              transparent
            );
          }
        `}
      >
        <div
          css={css`
            padding: 0.5em 0;
            overflow-x: auto;
            -webkit-overflow-scrolling: touch;
            display: flex;

            &::-webkit-scrollbar {
              height: 8px;
            }

            &::-webkit-scrollbar-track {
              background: ${theme.colors.grays[7]};
            }

            &::-webkit-scrollbar-thumb {
              background: ${theme.colors.grays[5]};
              border-radius: 99px;

              &:hover {
                background: ${theme.colors.grays[4]};
              }
            }

            &::before,
            &::after {
              flex-shrink: 0;
              width: 1em;
              content: '';
            }

            a {
              margin: 0 0.5em;

              padding: 0.5em 1.5em;
              background: ${buttonStyle.normal.bg};
              border-radius: ${theme.radii.pill};

              color: ${buttonStyle.normal.text};
              text-decoration: none;

              transition: all 0.1s ease-out;

              &:hover {
                background: ${buttonStyle.hover.bg};
                color: ${buttonStyle.hover.text};
              }
            }
          `}
        >
          <PhoneLink phone={siteData.phone_number}>{i18nData.call}</PhoneLink>

          <WhatsAppLink phone={siteData.whatsapp_number}>WhatsApp</WhatsAppLink>

          <ExternalLink href={siteData.google_maps_link.url}>
            {i18nData.directions}
          </ExternalLink>
        </div>
      </div>

      <div
        css={css`
          margin-left: auto;
          padding-left: 0.5em;
        `}
      >
        <LocaleSwitcher />
      </div>
    </section>
  )
}
