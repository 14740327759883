import { useLocale } from '@/store/locale'
import { UseMetadataQuery } from '@/types/graphql'
import { extractNodes } from '@apostrof/lib'
import { graphql, useStaticQuery } from 'gatsby'

const query = graphql`
  query UseMetadataQuery {
    allPrismicTranslation {
      edges {
        node {
          lang
          data {
            address
            book_whatsapp
            business_hours
            call
            directions
            duration
            get_in_touch
            minutes
            phone
          }
        }
      }
    }

    allPrismicSite {
      edges {
        node {
          lang
          data {
            phone_number
            whatsapp_number
            business_hours {
              html
            }
            google_maps_link {
              url
            }
            address {
              html
            }
          }
        }
      }
    }
  }
`

export function useMetadata() {
  const locale = useLocale()
  const metadata = useStaticQuery<UseMetadataQuery>(query)

  const translations = extractNodes(metadata.allPrismicTranslation)
  const siteData = extractNodes(metadata.allPrismicSite)

  const localeTranslation = translations.find(({ lang }) => lang === locale)
  const localeSiteData = siteData.find(({ lang }) => lang === locale)

  return {
    i18nData: localeTranslation.data,
    siteData: localeSiteData.data,
  }
}
