import { useTheme } from '@/store/theme'
import { AnnouncementFragment } from '@/types/graphql'
import { fluidFontSize } from '@apostrof/lib'
import { css } from '@emotion/core'
import { graphql } from 'gatsby'
import React from 'react'

export const fragment = graphql`
  fragment AnnouncementFragment on PrismicSiteDataType {
    announcement
    isAnnouncementEnabled: is_announcement_enabled
  }
`

type Props = {
  data: AnnouncementFragment
}

export const Announcement: React.FC<Props> = ({ data }) => {
  const theme = useTheme()
  const { announcement, isAnnouncementEnabled } = data

  return (
    isAnnouncementEnabled === 'Yes' && (
      <div
        css={css`
          padding: calc(0.5rem + 0.25%) 5%;
          background: ${theme.colors.grays[8]};
        `}
      >
        <p
          css={css`
            ${fluidFontSize(12, 14, 320, 1920)};
            color: ${theme.colors.grays[4]};
            line-height: 1.25;
            text-align: center;
          `}
        >
          {announcement}
        </p>
      </div>
    )
  )
}
