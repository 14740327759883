import { Announcement } from '@/components/Announcement'
import { Contact } from '@/components/Contact'
import { Footer } from '@/components/Footer'
import { Header } from '@/components/Header'
import { SEO } from '@/components/SEO'
import { SliceZone } from '@/components/SliceZone'
import { TopBar } from '@/components/TopBar'
import { PrismicLocaleCode } from '@/config/i18n'
import { Map } from '@/slices/Map'
import { HomepageQuery } from '@/types/graphql'
import { graphql } from 'gatsby'
import React from 'react'

export const query = graphql`
  query HomepageQuery($locale: String!) {
    prismicSite(lang: { eq: $locale }) {
      seoData: data {
        title
        metaDescription: meta_description
      }

      announcementData: data {
        ...AnnouncementFragment
      }

      sliceZoneData: data {
        ...SliceZoneFragment
      }
    }
  }
`

export type PageContext = {
  locale: PrismicLocaleCode
}

type Props = {
  data: HomepageQuery
}

const Homepage: React.FC<Props> = ({ data }) => {
  const { announcementData, sliceZoneData } = data.prismicSite

  return (
    <>
      <SEO
        title={data.prismicSite.seoData.title}
        metaDescription={data.prismicSite.seoData.metaDescription}
      />

      <Announcement data={announcementData} />

      <TopBar />

      <Header />

      <main>
        <SliceZone data={sliceZoneData} />
      </main>

      <Contact />

      <Map />

      <Footer />
    </>
  )
}

export default Homepage
