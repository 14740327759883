import { useTheme } from '@/store/theme'
import { css } from '@emotion/core'
import React from 'react'

type Props = {}

export const Map: React.FC<Props> = () => {
  const theme = useTheme()

  return (
    <section
      css={css`
        padding: calc(2rem + 3%) 5%;
        background: ${theme.colors.grays[9]};
      `}
    >
      <div
        css={css`
          max-width: ${theme.maxWidth};
          margin: 0 auto;

          border-radius: ${theme.radii.normal};
          overflow: hidden;
          /* Workaround to make border-radius work on iOS Safari */
          mask-image: -webkit-radial-gradient(white, white, white);
        `}
      >
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3965.4100138914564!2d107.15126011519052!3d-6.340909395411272!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e699bbf18618017%3A0xd0bc2d583a28966d!2sNEO+Reflexology!5e0!3m2!1sen!2sid!4v1560355267006!5m2!1sen!2sid"
          allowFullScreen
          title="Embedded Google Maps centered at NEO Reflexology"
          width="100%"
          height="400"
          css={css`
            border: 0;
          `}
        />
      </div>
    </section>
  )
}
