import { mq } from '@/config/mediaqueries'
import { useLocale } from '@/store/locale'
import { useTheme } from '@/store/theme'
import { useMetadata } from '@/utils/useMetadata'
import {
  ExternalLink,
  fluidFontSize,
  PhoneLink,
  WhatsAppLink,
} from '@apostrof/lib'
import { css } from '@emotion/core'
import React from 'react'

export const Contact: React.FC = () => {
  const theme = useTheme()
  const locale = useLocale()
  const { i18nData, siteData } = useMetadata()

  return (
    <section
      css={css`
        padding: calc(2rem + 3%) 5%;
        background: ${theme.colors.grays[9]};
      `}
    >
      <div
        css={css`
          max-width: ${theme.maxWidth};
          margin: 0 auto;

          ${mq.sm} {
            display: flex;
          }
        `}
      >
        <h2
          css={css`
            margin-bottom: 1em;

            color: ${theme.colors.grays[2]};
            ${fluidFontSize(20, 48, 320, 1920)};
            line-height: 1;
            font-weight: 900;
            font-style: italic;

            ${mq.sm} {
              width: 40%;
              margin-right: 5%;
            }
          `}
        >
          {i18nData.get_in_touch}
        </h2>

        <dl
          css={css`
            ${fluidFontSize(16, 20, 320, 1920)};

            dt {
              &:not(:first-of-type) {
                margin-top: 2rem;
              }

              color: ${theme.colors.grays[4]};
              font-size: 0.75em;
              text-transform: uppercase;
              letter-spacing: 0.03em;
              font-weight: 800;
            }

            dd {
              margin-top: 0.25em;

              color: ${theme.colors.grays[3]};

              p + p {
                margin-top: 0.5em;
              }
            }

            a {
              color: inherit;
              text-decoration: none;

              &:hover {
                text-decoration: underline;
              }
            }

            ${mq.sm} {
              width: 55%;
            }
          `}
        >
          <dt>{i18nData.phone}</dt>
          <dd>
            <PhoneLink phone={siteData.phone_number}>
              {siteData.phone_number}
            </PhoneLink>
          </dd>

          <dt>WhatsApp</dt>
          <dd>
            <WhatsAppLink
              phone={siteData.whatsapp_number}
              text={`[Visitor language: ${locale}]\n\n`}
            >
              {siteData.whatsapp_number}
            </WhatsAppLink>
          </dd>

          <dt>{i18nData.business_hours}</dt>
          <dd
            dangerouslySetInnerHTML={{ __html: siteData.business_hours.html }}
          />

          <dt>{i18nData.address}</dt>
          <dd>
            <ExternalLink href={siteData.google_maps_link.url}>
              <div
                dangerouslySetInnerHTML={{ __html: siteData.address.html }}
              />
            </ExternalLink>
          </dd>
        </dl>
      </div>
    </section>
  )
}
