import { mq } from '@/config/mediaqueries'
import { useTheme } from '@/store/theme'
import { css } from '@emotion/core'
import React from 'react'

export const Logo: React.FC = () => {
  const theme = useTheme()

  return (
    <h1
      css={css`
        color: ${theme.colors.grays[3]};
        font-size: 1rem;
        line-height: 1;

        svg {
          width: 64px;

          ${mq.sm} {
            width: 72px;
          }

          ${mq.md} {
            width: 80px;
          }

          ${mq.lg} {
            width: 88px;
          }
        }
      `}
    >
      <svg viewBox="0 0 399 130" xmlns="http://www.w3.org/2000/svg">
        <g fill="currentColor">
          <path d="m398.96 65.09c-.47 36.27-25.19 61.89-61.93 63.87-23.19 1.25-43.54-5.16-58.83-23.6-22.77-27.46-20.16-85.71 32.4-101.83 25.76-7.9 49.06-2.44 69.23 15.44 12.53 11.1 19.36 28.06 19.13 46.12zm-107.16-.06c0 22.87 16.43 39.3 39.49 39.43 23.52.14 40.91-16.54 40.94-39.25 0-22.94-16.93-39.31-40.74-39.3200046-23.11-.0099954-39.68 16.3100046-39.69 39.1400046z" />
          <path d="m26 51.99v13.64c0 16 .11 32-.07 48-.1 8.51-4.28 13.12-11.55 13.54s-12.89-4.09-13.89-11.41c-.18321089-1.486081-.26673096-2.982761-.25-4.48 0-30.5 0-61 0-91.5 0-1.33.06-2.66.16-4 .33-4.54 1.5-8.9 6.2-10.54 5.17-1.8 10.85-2.29 15.07 1.95 6.56 6.6 12.7 13.64 18.81 20.68 13.43 15.47 26.71 31.07 40.08 46.6.82 1 1.87 1.71 3.86 3.51 0-3 0-4.68 0-6.41 0-18.16-.06-36.33.08-54.5.08-10.75 9.47-17.27 18.23-12.71 4.86 2.54 8 6.51 8 12.33 0 32.5.08 65-.06 97.5 0 10.78-10.32 16.44-19.9 10.89-3.3713612-2.123094-6.3684533-4.789053-8.87-7.89-17.87-20.89-35.58-41.9-53.35-62.87-.48-.48-1-.87-2.55-2.33z" />
          <path d="m190.64 79.11c-16.83 0-33.66-.06-50.49 0-5.59 0-9.87-2.17-12.15-7.14s-2.44-10.46 1.61-14.48c2.36-2.33 6.24-4.41 9.45-4.43 34.32-.31 68.65-.26 103-.12 7.76 0 12.63 5.53 12.541226 13.26-.091226 7.73-5.131226 12.76-12.951226 12.81-17 .11-34 0-51 0z" />
          <path d="m188.31 32.55c-14.16 0-28.32.05-42.49 0-8.34 0-13.79-5.26-13.83-13 0-8 5.2-13 13.77-13h86c7.93 0 12.86 5.22 12.850015 13.33 0 8-4.590015 12.63-12.790015 12.69-14.49.1-29 0-43.49 0z" />
          <path d="m188.47 99.2h43.48c7.89 0 12.6 4.89 12.610016 12.89.009984 8-4.590016 13-12.540016 13-28.82.093333-57.64.093333-86.46 0-7.77 0-12.38-4.08-13.45-11.33-1.18-8.06 4.23-14.54 12.37-14.58 14.66-.06 29.32.02 43.99.02z" />
        </g>
      </svg>
    </h1>
  )
}
