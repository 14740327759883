import { Service } from '@/components/Service'
import { useTheme } from '@/store/theme'
import { ServicesFragment } from '@/types/graphql'
import { fluidFontSize } from '@apostrof/lib'
import { css } from '@emotion/core'
import { graphql } from 'gatsby'
import React from 'react'

export const fragment = graphql`
  fragment ServicesFragment on PrismicSiteBodyServices {
    primary {
      heading
      description {
        html
      }
    }

    items {
      service {
        slug
        document {
          __typename

          ... on PrismicService {
            data {
              ...ServiceFragment
            }
          }
        }
      }
    }
  }
`

type Props = {
  data: ServicesFragment
}

function isEven(num: number) {
  return num % 2 === 0
}

export const Services: React.FC<Props> = ({ data }) => {
  const theme = useTheme()
  const { heading, description } = data.primary

  return (
    <section
      css={css`
        background: ${theme.colors.grays[9]};
        padding: calc(2rem + 3%) 5%;
      `}
    >
      <div
        css={css`
          max-width: ${theme.maxWidth};
          margin: 0 auto;
        `}
      >
        <header>
          <h2
            css={css`
              margin-bottom: 0.25em;

              color: ${theme.colors.grays[2]};
              ${fluidFontSize(20, 48, 320, 1920)};
              line-height: 1;
              font-weight: 900;
              font-style: italic;
            `}
          >
            {heading}
          </h2>
          {description && description.html ? (
            <div
              dangerouslySetInnerHTML={{ __html: description.html }}
              css={css`
                color: ${theme.colors.grays[4]};
                ${fluidFontSize(14, 20, 320, 1920)};
                font-style: italic;
              `}
            />
          ) : null}
        </header>

        <ul
          css={css`
            list-style: none;
          `}
        >
          {data.items.map(({ service }, index) => {
            switch (service.document.__typename) {
              case 'PrismicService':
                return (
                  <li
                    key={service.slug}
                    css={css`
                      margin-top: calc(2rem + 3%);
                      margin-left: auto;
                    `}
                  >
                    <Service
                      data={service.document.data}
                      imagePosition={isEven(index + 1) ? 'right' : 'left'}
                    />
                  </li>
                )
              default:
                return null
            }
          })}
        </ul>
      </div>
    </section>
  )
}
