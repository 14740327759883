import { mq } from '@/config/mediaqueries'
import { useTheme } from '@/store/theme'
import { HeroSectionFragment } from '@/types/graphql'
import { fluidFontSize, hexToRGB } from '@apostrof/lib'
import { css } from '@emotion/core'
import { graphql, Link } from 'gatsby'
import Img from 'gatsby-image'
import React from 'react'

export const fragment = graphql`
  fragment HeroSectionFragment on PrismicSiteBodyHeroSection {
    primary {
      image {
        localFile {
          childImageSharp {
            fluid(
              quality: 85
              maxWidth: 1920
              maxHeight: 960
              fit: COVER
              cropFocus: CENTER
              srcSetBreakpoints: [320, 640, 960, 1280, 1600, 1920]
            ) {
              ...SharpFluid
            }
          }
        }
      }
      title {
        text
      }
      subtitle {
        text
      }
      button_label
      button_link {
        url
      }
    }
  }
`

type Props = {
  data: HeroSectionFragment
}

export const HeroSection: React.FC<Props> = ({ data }) => {
  const theme = useTheme()
  const { image, title, subtitle, button_label, button_link } = data.primary

  return (
    <section>
      <div
        css={css`
          position: relative;
        `}
      >
        <Img fluid={image.localFile.childImageSharp.fluid} alt="" />

        <div
          css={css`
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background: ${hexToRGB(theme.colors.grays[8], 0.5)};
            padding: 0 5%;
          `}
        >
          <div
            css={css`
              height: 100%;

              display: flex;
              /* flex-direction: column; */
              justify-content: flex-start;
              align-items: flex-end;
              padding-bottom: 5%;

              ${mq.lg} {
                padding-bottom: 0;
                justify-content: center;
                align-items: center;
                text-align: center;
              }
            `}
          >
            <div
              css={css`
                ${fluidFontSize(20, 64, 320, 1920)};
                text-shadow: ${theme.textShadow};
              `}
            >
              <h2
                css={css`
                  margin-bottom: 0.25em;

                  color: ${theme.colors.grays[1]};
                  font-size: 1em;
                  line-height: 1.1;
                  font-weight: 900;
                  font-style: italic;
                `}
              >
                {title.text}
              </h2>
              <p
                css={css`
                  color: ${theme.colors.grays[3]};
                  ${fluidFontSize(14, 24, 320, 1920)};
                  font-style: italic;
                `}
              >
                {subtitle.text}
              </p>
            </div>

            {/* temporarily disable CTA */}
            {false && (
              <Link
                to={button_link.url}
                css={css`
                  padding: 0.25em 0.5em;
                  background: ${theme.colors.grays[8]};
                  border-radius: 0.25rem;

                  color: ${theme.colors.grays[3]};
                  ${fluidFontSize(12, 20, 320, 1920)};
                  font-weight: 800;
                  text-decoration: none;

                  transition: all 0.1s ease-out;

                  &:hover {
                    background: ${theme.colors.grays[7]};
                  }

                  ${mq.sm} {
                    padding: 0.5em 1em;
                  }
                `}
              >
                {button_label}
              </Link>
            )}
          </div>
        </div>
      </div>
    </section>
  )
}
