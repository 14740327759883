import { mq } from '@/config/mediaqueries'
import { useLocale } from '@/store/locale'
import { useTheme } from '@/store/theme'
import { ServiceFragment } from '@/types/graphql'
import { useMetadata } from '@/utils/useMetadata'
import { fluidFontSize, WhatsAppLink } from '@apostrof/lib'
import { css } from '@emotion/core'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import React from 'react'

export const fragment = graphql`
  fragment ServiceFragment on PrismicServiceDataType {
    name
    description {
      html
    }
    duration {
      minutes
    }
    image {
      localFile {
        childImageSharp {
          fluid(maxWidth: 1024, quality: 95) {
            ...SharpFluid
          }
        }
      }
    }
  }
`

type Props = {
  data: ServiceFragment
  imagePosition?: 'left' | 'right'
}

export const Service: React.FC<Props> = ({ data, imagePosition = 'left' }) => {
  const theme = useTheme()
  const locale = useLocale()
  const { i18nData, siteData } = useMetadata()
  const { name, description, duration, image } = data

  const hasImage = !!image && !!image.localFile

  return (
    <section
      css={css`
        ${mq.md} {
          display: flex;
          justify-content: center;
          align-items: center;

          ${hasImage &&
            imagePosition === 'right' &&
            css`
              flex-direction: row-reverse;
            `}
        }
      `}
    >
      {hasImage ? (
        <div
          css={css`
            border-radius: ${theme.radii.normal};
            overflow: hidden;

            ${mq.md} {
              width: 60%;
            }
          `}
        >
          <Img fluid={image.localFile.childImageSharp.fluid} alt="" />
        </div>
      ) : null}

      <div
        css={css`
          position: relative;
          z-index: 1;

          padding: 0 5%;
          transform: translateY(-10%);

          ${mq.md} {
            width: 40%;
            padding: 0;
            transform: none;
          }
        `}
      >
        <div
          css={css`
            border-radius: ${theme.radii.normal};
            overflow: hidden;
            box-shadow: ${theme.elevations[1]};

            ${mq.md} {
              ${hasImage &&
                imagePosition === 'left' &&
                css`
                  margin-left: -5%;
                `}

              ${hasImage &&
                imagePosition === 'right' &&
                css`
                  margin-right: -5%;
                `}
            }
          `}
        >
          <header
            css={css`
              padding: 10% 5%;
              background: ${theme.colors.grays[8]};
              text-align: center;
            `}
          >
            <h3
              css={css`
                margin-bottom: 0.5em;

                color: ${theme.colors.grays[2]};
                line-height: 1.2;
                ${fluidFontSize(20, 24, 320, 1920)};
                font-weight: 900;
                letter-spacing: 0.03em;
              `}
            >
              {name}
            </h3>

            {description && description.html && (
              <div
                dangerouslySetInnerHTML={{ __html: description.html }}
                css={css`
                  color: ${theme.colors.grays[4]};
                  ${fluidFontSize(14, 16, 320, 1920)};
                `}
              />
            )}
          </header>

          <div
            css={css`
              padding: calc(0.5rem + 2%) 5%;
              background: ${theme.colors.grays[7]};
            `}
          >
            <p
              css={css`
                text-align: center;

                color: ${theme.colors.grays[4]};
                font-size: 0.75rem;
                font-weight: 800;
                text-transform: uppercase;
                letter-spacing: 0.05em;
              `}
            >
              {i18nData.book_whatsapp}:
            </p>

            <div
              css={css`
                margin-top: 0.5rem;

                display: flex;
                justify-content: center;
                flex-wrap: wrap;
                max-width: 100%;
              `}
            >
              {duration.map(({ minutes }) => {
                const whatsAppTemplateText =
                  `[Visitor language: ${locale}]\n\n` +
                  `Halo, saya mau reservasi / ` +
                  `_Hello, I’d like to reserve_:\n` +
                  `*${name}* (${minutes} menit / _mins_).\n\n` +
                  `Nama / ` +
                  `_Name_: \n\n` +
                  `Waktu / _Date & Time_: \n`

                return (
                  <WhatsAppLink
                    key={minutes}
                    phone={siteData.whatsapp_number}
                    text={whatsAppTemplateText}
                    css={css`
                      margin: 0.25rem 0;
                      margin-left: 0.5rem;
                      padding: 0.5em 1.25em;
                      background: ${theme.colors.grays[8]};
                      border-radius: ${theme.radii.pill};
                      box-shadow: ${theme.elevations[2]};

                      color: ${theme.colors.grays[3]};
                      font-size: 1rem;
                      font-weight: 800;
                      white-space: nowrap;
                      text-decoration: none;

                      transition: all 0.1s ease-out;

                      &:hover {
                        background: ${theme.colors.grays[6]};
                        color: ${theme.colors.grays[1]};
                      }
                    `}
                  >
                    <span>{minutes}</span>
                    <span
                      css={css`
                        margin-left: 0.25rem;
                        font-size: 0.75em;
                        font-weight: 900;
                      `}
                    >
                      {i18nData.minutes.toLowerCase()}
                    </span>
                  </WhatsAppLink>
                )
              })}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
