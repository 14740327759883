import { useTheme } from '@/store/theme'
import { fluidFontSize } from '@apostrof/lib'
import { css } from '@emotion/core'
import React from 'react'
import { Logo } from './Logo'

export const Header: React.FC = () => {
  const theme = useTheme()

  return (
    <header
      css={css`
        position: relative;
      `}
    >
      <div
        css={css`
          position: absolute;
          z-index: 1;

          left: 50%;
          transform: translateX(-50%);
          padding: calc(0.25rem + 2%) 5% 0;

          text-align: center;
          text-shadow: ${theme.textShadow};
        `}
      >
        <Logo />

        <div
          css={css`
            margin-top: 1em;

            color: ${theme.colors.grays[3]};
            ${fluidFontSize(12, 16, 320, 1920)};
            line-height: 1.1;
            font-style: italic;

            display: flex;
            flex-direction: column;
            align-items: center;
          `}
        >
          <p>Family Reflexology</p>
          <p
            css={css`
              display: grid;
              grid-template-columns: 1fr auto 1fr;
              align-items: center;
              grid-column-gap: 0.5rem;
              padding: 0.25rem;
              width: 100%;

              color: ${theme.colors.grays[4]};

              &::before,
              &::after {
                content: '';
                height: 1px;
                background: ${theme.colors.grays[5]};
              }
            `}
          >
            &amp;
          </p>
          <p>Body Massage</p>
        </div>
      </div>
    </header>
  )
}
