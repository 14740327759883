import { useTheme } from '@/store/theme'
import { ExternalLink, fluidFontSize } from '@apostrof/lib'
import { css } from '@emotion/core'
import React from 'react'

export const Footer: React.FC = () => {
  const theme = useTheme()

  return (
    <footer
      css={css`
        padding: calc(2rem + 3%) 5%;
        background: ${theme.colors.grays[9]};
      `}
    >
      <dl
        css={css`
          display: flex;
          justify-content: space-between;

          ${fluidFontSize(14, 16, 320, 1920)};

          dt {
            color: ${theme.colors.grays[6]};
            font-size: 0.75em;
            text-transform: uppercase;
            letter-spacing: 0.03em;
            font-weight: 900;
          }

          dd {
            margin-top: 0.25em;

            color: ${theme.colors.grays[5]};
            line-height: 1.2;
            font-weight: 800;
          }

          a {
            color: inherit;
            text-decoration: none;

            &:hover {
              text-decoration: underline;
            }
          }
        `}
      >
        <div>
          <dt>Copyright</dt>
          <dd>NEO Family&nbsp;Reflexology</dd>
        </div>

        <div
          css={css`
            margin-left: 5%;

            text-align: right;

            white-space: nowrap;
          `}
        >
          <dt>Website by</dt>
          <dd>
            <ExternalLink href="https://github.com/ryanditjia/">
              Ryandi Tjia
            </ExternalLink>
          </dd>
        </div>
      </dl>
    </footer>
  )
}
